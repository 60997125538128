<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="primary--text-kh">{{$t('menu.report')}}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="formReport" lazy-validation>
          <v-row>
            <v-col cols="12" md="5">
              <v-select :items="reportItems" v-model="selectedReport" label="សូមជ្រើសរើសរបាយការណ៍" :rules="rule.requiredField()" outlined dense hide-details>
                <template slot="selection" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.text}}
                  </span>
                  <span v-else>{{data.item.text}}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.text}}
                  </span>
                  <span v-else>{{data.item.text}}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select :items="departmentList" v-model="reportRequest.department" item-text="nameKh" return-object label="សូមជ្រើសរើសស្ថាប័នឬនាយកដ្ឋាន" @change="changeDepartment($event)" clearable outlined dense hide-details>
                <template slot="selection" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.nameKh}}
                  </span>
                  <span v-else>{{data.item.nameEn}}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.nameKh}}
                  </span>
                  <span v-else>{{data.item.nameEn}}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select :items="positionList" v-model="reportRequest.position" item-text="nameKh" return-object label="សូមជ្រើសរើសតួនាទី" clearable outlined dense hide-details>
                <template slot="selection" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.nameKh}}
                  </span>
                  <span v-else>{{data.item.nameEn}}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.nameKh}}
                  </span>
                  <span v-else>{{data.item.nameEn}}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field v-model="reportRequest.startDate" label="កាលបរិច្ឆេទបម្រើការងារ" outlined dense type="date" hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn class="btn" color="primary" @click="generate">បង្ហាញ</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-3" v-if="selectedReportText != ''">
      <v-card-title class="justify-center">
        <h3 style="font-family: Khmer OS Muol">របាយការណ៍</h3>
      </v-card-title>
      <v-card-subtitle class="text-center">
        <h3>{{selectedReportText}} {{selectedDeptText}}</h3>
      </v-card-subtitle>
      <v-card-text>
        <v-simple-table class="bordered-table" v-if="reportStats.length > 0">
          <tr>
            <th>ល.រ</th>
            <th>ស្ថាប័នបម្រើមុខងារ</th>
            <th>ចំនួន</th>
            <th>ប្រុស</th>
            <th>ស្រី</th>
          </tr>
          <tr style="text-align:center" v-for="(row, index) in reportStats" :key="row.id">
            <td>{{index + 1}}</td>
            <td>{{row.department.nameKh}}</td>
            <td>{{row.male + row.female}}</td>
            <td>{{row.male}}</td>
            <td>{{row.female}}</td>
          </tr>
        </v-simple-table>
      </v-card-text>

      <v-card-subtitle class="text-center">
        <h3>បញ្ជីឈ្មោះបុគ្គលិក</h3>
      </v-card-subtitle>
      <v-card-text v-if="reportResult.length > 0">
        <v-simple-table class="bordered-table">
          <tr>
            <th>ល.រ</th>
            <th>ឈ្មោះ</th>
            <th>ស្ថាប័នបម្រើមុខងារ</th>
            <th>តួនាទី</th>
            <th>កាលបរិច្ឆេទបម្រើការងារ</th>
          </tr>
          <tr style="text-align:center" v-for="(row, index) in reportResult" :key="row.id">
            <td>{{index + 1}}</td>
            <td>{{row.nameKh}}</td>
            <td v-if="row.department != null">{{row.department.nameKh}}</td>
            <td v-if="row.position != null">{{row.position.nameKh}}</td>
            <td>{{row.startDate}}</td>
          </tr>
        </v-simple-table>
      </v-card-text>
      <v-card-text v-else>
        <p style="text-align:center">No data available</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiCalendarAlert,
  mdiPlus,
  mdiAttachment,
  mdiContentSave,
  mdiDelete,
  mdiClose,
  mdiEye,
  mdiEyeCircle,
  mdiFileEdit,
  mdiReceiptTextEdit
} from '@mdi/js'
import SystemService from '@/services/service.system'
import ReportService from '@/services/service.report'
import Rule from '@/plugins/rules.js'

export default {
  props: {
    data: Object
  },
  name: 'custom-report',
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiAttachment,
        mdiContentSave,
        mdiDelete,
        mdiClose,
        mdiEye,
        mdiEyeCircle,
        mdiFileEdit,
        mdiReceiptTextEdit
      },
      rule: '',
      reportItems: [{ value: 1, text: 'បុគ្គលិកតាមតុលាការ ឬផ្នែក ឬនាយកដ្ឋាន' }],

      reportRequest: {},
      reportResult: [],
      reportStats: [],
      positionList: [],
      departmentList: [],
      selectedReport: '',
      selectedReportText: '',
      selectedDeptText: '',
      reportHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខលិខិត', value: 'warrantLetterNo' },
        { text: 'ដីការចេញ', value: 'warrantDate' },
        { text: 'បង្ហាញខ្លួន', value: 'appearanceDate' },
        { text: 'ឈ្មោះអយ្យការ', value: 'prosecutorName' },
        { text: 'ភាគីពាក់ព័ន្ធ', value: 'parties' },
        { text: 'កែប្រែ', value: 'actions' }
      ]
    }
  },
  async created() {
    this.rule = Rule

    SystemService.getDepartmentAll().then(response => {
      this.departmentList = response.data
    })
  },
  watch: {},
  methods: {
    handleSelecteReport(value) {
      const selectedItem = this.reportItems.find(item => item.value === value)
      this.selectedReportText = selectedItem ? selectedItem.text : ''
    },
    async generate() {
      //   alert(this.reportRequest.startDate)
      if (!this.$refs.formReport.validate()) return

      const selectedItem = this.reportItems.find(
        item => item.value === this.selectedReport
      )
      this.selectedReportText = selectedItem ? selectedItem.text : ''

      if (this.reportRequest.department != null) {
        const selectedDept = this.departmentList.find(
          item => item.id === this.reportRequest.department.id
        )

        this.selectedDeptText = selectedDept ? ' - ' + selectedDept.nameKh : ''
      }

      await ReportService.getStaffReport(this.reportRequest).then(response => {
        this.reportResult = response.data
      })

      if (this.reportResult.length > 0 && this.reportRequest.startDate) {
        this.reportResult = this.reportResult.filter(item => {
          if (item.startDate) {
            return (
              new Date(item.startDate) > new Date(this.reportRequest.startDate)
            )
          }
        })
      }

      var countMale = 0
      var countFemale = 0
      var selectedDept = ''
      var selectedDeptId = 0
      this.reportStats = []

      this.reportResult.forEach(element => {
        if (selectedDeptId == 0) {
          selectedDeptId = element.department.id
        }

        if (selectedDeptId == element.department.id) {
          selectedDept = element.department
          if (element.gender == 0) {
            countMale++
          } else {
            countFemale++
          }
        } else {
          this.reportStats.push({
            department: selectedDept,
            male: countMale,
            female: countFemale
          })

          selectedDeptId = element.department.id
          selectedDept = element.department

          countMale = 0
          countFemale = 0

          if (element.gender == 0) {
            countMale++
          } else {
            countFemale++
          }
        }
      })

      if (countMale != 0 || countFemale != 0) {
        this.reportStats.push({
          department: selectedDept,
          male: countMale,
          female: countFemale
        })
      }
    },
    changeDepartment: function(event) {
      if (event == null) {
        this.selectedDeptText = ''
        return
      }
      this.reportRequest.position = null
      SystemService.getPositionByDepartmentId(event.id).then(response => {
        this.positionList = response.data
      })
    }
  }
}
</script>
<style scoped>
.right-side-dialog {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 600px;
  width: 100%;
  overflow-y: auto;
}

.right-side-dialog-800 {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 800px;
  width: 100%;
  overflow-y: auto;
}

.slide-x-transition-enter-active,
.slide-x-transition-leave-active {
  transition: transform 0.3s ease;
}

.slide-x-transition-enter {
  transform: translateX(100%);
}

.slide-x-transition-leave-to {
  transform: translateX(100%);
}
</style>